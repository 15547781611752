import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_2680dfcc from 'nuxt_plugin_plugin_2680dfcc' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_wmIcons_6aeba8da from 'nuxt_plugin_wmIcons_6aeba8da' // Source: ./wmIcons.js (mode: 'all')
import nuxt_plugin_nuxtplugin50632827_12de701d from 'nuxt_plugin_nuxtplugin50632827_12de701d' // Source: ./nuxt.plugin.50632827.js (mode: 'server')
import nuxt_plugin_image_0c6607c3 from 'nuxt_plugin_image_0c6607c3' // Source: ./image.js (mode: 'all')
import nuxt_plugin_vuescrollto_02cfca41 from 'nuxt_plugin_vuescrollto_02cfca41' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_c08bcb20 from 'nuxt_plugin_cookieuniversalnuxt_c08bcb20' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_61154c73 from 'nuxt_plugin_pluginutils_61154c73' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_5493c087 from 'nuxt_plugin_pluginseo_5493c087' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_26f488fe from 'nuxt_plugin_pluginrouting_26f488fe' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_0d19999e from 'nuxt_plugin_pluginmain_0d19999e' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_runtimeplugin08d43779_2fd01c84 from 'nuxt_plugin_runtimeplugin08d43779_2fd01c84' // Source: ./runtime.plugin.08d43779.mjs (mode: 'all')
import nuxt_plugin_customerplugin_ee29e7ca from 'nuxt_plugin_customerplugin_ee29e7ca' // Source: ./customer.plugin.js (mode: 'all')
import nuxt_plugin_runtimeplugin00153244_646944a9 from 'nuxt_plugin_runtimeplugin00153244_646944a9' // Source: ./runtime.plugin.00153244.mjs (mode: 'all')
import nuxt_plugin_storeLocatorplugin3be19f1e_50699088 from 'nuxt_plugin_storeLocatorplugin3be19f1e_50699088' // Source: ./storeLocator.plugin.3be19f1e.ts (mode: 'all')
import nuxt_plugin_gtmpluginclient_ef292a34 from 'nuxt_plugin_gtmpluginclient_ef292a34' // Source: ./gtm.plugin.client.js (mode: 'client')
import nuxt_plugin_portalvue_2a435e8b from 'nuxt_plugin_portalvue_2a435e8b' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_runtimeplugin0d15f0c6_3b3d01fb from 'nuxt_plugin_runtimeplugin0d15f0c6_3b3d01fb' // Source: ./runtime.plugin.0d15f0c6.mjs (mode: 'all')
import nuxt_plugin_runtimeplugin27f1d9fa_7461c846 from 'nuxt_plugin_runtimeplugin27f1d9fa_7461c846' // Source: ./runtime.plugin.27f1d9fa.mjs (mode: 'all')
import nuxt_plugin_runtimeplugin0f449e2e_0dac875b from 'nuxt_plugin_runtimeplugin0f449e2e_0dac875b' // Source: ./runtime.plugin.0f449e2e.mjs (mode: 'all')
import nuxt_plugin_gtm_5db3e39e from 'nuxt_plugin_gtm_5db3e39e' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_distplugin28ba873e_74ebbb10 from 'nuxt_plugin_distplugin28ba873e_74ebbb10' // Source: ./dist.plugin.28ba873e.mjs (mode: 'all')
import nuxt_plugin_pluginsfc7bcace59_42f66a13 from 'nuxt_plugin_pluginsfc7bcace59_42f66a13' // Source: ./plugins.fc.7bcace59.mjs (mode: 'all')
import nuxt_plugin_corepluginb942efca_29ec4fb4 from 'nuxt_plugin_corepluginb942efca_29ec4fb4' // Source: ./core.plugin.b942efca.ts (mode: 'all')
import nuxt_plugin_pluginstokenexpiredc6d87cac_720fc8f4 from 'nuxt_plugin_pluginstokenexpiredc6d87cac_720fc8f4' // Source: ./plugins.token-expired.c6d87cac.ts (mode: 'all')
import nuxt_plugin_pluginsdompurify8950392c_49ca7348 from 'nuxt_plugin_pluginsdompurify8950392c_49ca7348' // Source: ./plugins.dompurify.8950392c.ts (mode: 'all')
import nuxt_plugin_pluginsi18n4548a121_1e9b0c04 from 'nuxt_plugin_pluginsi18n4548a121_1e9b0c04' // Source: ./plugins.i18n.4548a121.ts (mode: 'all')
import nuxt_plugin_pluginsfixLocaleRoutes4c770982_76916a9e from 'nuxt_plugin_pluginsfixLocaleRoutes4c770982_76916a9e' // Source: ./plugins.fixLocaleRoutes.4c770982.ts (mode: 'all')
import nuxt_plugin_pluginse2etesting00897c3b_41790d93 from 'nuxt_plugin_pluginse2etesting00897c3b_41790d93' // Source: ./plugins.e2e-testing.00897c3b.js (mode: 'all')
import nuxt_plugin_deviceplugin_2e794b5a from 'nuxt_plugin_deviceplugin_2e794b5a' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_70d02438 from 'nuxt_plugin_workbox_70d02438' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_69a710f7 from 'nuxt_plugin_metaplugin_69a710f7' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_6e4eb66b from 'nuxt_plugin_iconplugin_6e4eb66b' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_storeConfigPlugin_cbd09bc6 from 'nuxt_plugin_storeConfigPlugin_cbd09bc6' // Source: ../node_modules/@wemade-vsf/core/lib/runtime/plugins/storeConfigPlugin.mjs (mode: 'server')
import nuxt_plugin_isQuoteProduct_d817978c from 'nuxt_plugin_isQuoteProduct_d817978c' // Source: ../plugins/isQuoteProduct.ts (mode: 'all')
import nuxt_plugin_meta_44beaca8 from 'nuxt_plugin_meta_44beaca8' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Grønt Maskin","titleTemplate":"%s - Grønt Maskin","meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"generator","content":"Vue Storefront 2"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"bodyAttrs":{"id":"html-body"},"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-K573MT8')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-K573MT8&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_2680dfcc === 'function') {
    await nuxt_plugin_plugin_2680dfcc(app.context, inject)
  }

  if (typeof nuxt_plugin_wmIcons_6aeba8da === 'function') {
    await nuxt_plugin_wmIcons_6aeba8da(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin50632827_12de701d === 'function') {
    await nuxt_plugin_nuxtplugin50632827_12de701d(app.context, inject)
  }

  if (typeof nuxt_plugin_image_0c6607c3 === 'function') {
    await nuxt_plugin_image_0c6607c3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_02cfca41 === 'function') {
    await nuxt_plugin_vuescrollto_02cfca41(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_c08bcb20 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_c08bcb20(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_61154c73 === 'function') {
    await nuxt_plugin_pluginutils_61154c73(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_5493c087 === 'function') {
    await nuxt_plugin_pluginseo_5493c087(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_26f488fe === 'function') {
    await nuxt_plugin_pluginrouting_26f488fe(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_0d19999e === 'function') {
    await nuxt_plugin_pluginmain_0d19999e(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin08d43779_2fd01c84 === 'function') {
    await nuxt_plugin_runtimeplugin08d43779_2fd01c84(app.context, inject)
  }

  if (typeof nuxt_plugin_customerplugin_ee29e7ca === 'function') {
    await nuxt_plugin_customerplugin_ee29e7ca(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin00153244_646944a9 === 'function') {
    await nuxt_plugin_runtimeplugin00153244_646944a9(app.context, inject)
  }

  if (typeof nuxt_plugin_storeLocatorplugin3be19f1e_50699088 === 'function') {
    await nuxt_plugin_storeLocatorplugin3be19f1e_50699088(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtmpluginclient_ef292a34 === 'function') {
    await nuxt_plugin_gtmpluginclient_ef292a34(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_2a435e8b === 'function') {
    await nuxt_plugin_portalvue_2a435e8b(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin0d15f0c6_3b3d01fb === 'function') {
    await nuxt_plugin_runtimeplugin0d15f0c6_3b3d01fb(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin27f1d9fa_7461c846 === 'function') {
    await nuxt_plugin_runtimeplugin27f1d9fa_7461c846(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin0f449e2e_0dac875b === 'function') {
    await nuxt_plugin_runtimeplugin0f449e2e_0dac875b(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_5db3e39e === 'function') {
    await nuxt_plugin_gtm_5db3e39e(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin28ba873e_74ebbb10 === 'function') {
    await nuxt_plugin_distplugin28ba873e_74ebbb10(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfc7bcace59_42f66a13 === 'function') {
    await nuxt_plugin_pluginsfc7bcace59_42f66a13(app.context, inject)
  }

  if (typeof nuxt_plugin_corepluginb942efca_29ec4fb4 === 'function') {
    await nuxt_plugin_corepluginb942efca_29ec4fb4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginstokenexpiredc6d87cac_720fc8f4 === 'function') {
    await nuxt_plugin_pluginstokenexpiredc6d87cac_720fc8f4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsdompurify8950392c_49ca7348 === 'function') {
    await nuxt_plugin_pluginsdompurify8950392c_49ca7348(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsi18n4548a121_1e9b0c04 === 'function') {
    await nuxt_plugin_pluginsi18n4548a121_1e9b0c04(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfixLocaleRoutes4c770982_76916a9e === 'function') {
    await nuxt_plugin_pluginsfixLocaleRoutes4c770982_76916a9e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etesting00897c3b_41790d93 === 'function') {
    await nuxt_plugin_pluginse2etesting00897c3b_41790d93(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_2e794b5a === 'function') {
    await nuxt_plugin_deviceplugin_2e794b5a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_70d02438 === 'function') {
    await nuxt_plugin_workbox_70d02438(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_69a710f7 === 'function') {
    await nuxt_plugin_metaplugin_69a710f7(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_6e4eb66b === 'function') {
    await nuxt_plugin_iconplugin_6e4eb66b(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_storeConfigPlugin_cbd09bc6 === 'function') {
    await nuxt_plugin_storeConfigPlugin_cbd09bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_isQuoteProduct_d817978c === 'function') {
    await nuxt_plugin_isQuoteProduct_d817978c(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_44beaca8 === 'function') {
    await nuxt_plugin_meta_44beaca8(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
