import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5246dba0 = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _30a1aba4 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _1edb950f = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _bcd2f3b8 = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _f119e44c = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _7deec431 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _8474314a = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _a38eaec2 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _547790ab = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _4a7f958f = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _0d4da442 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _05259898 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _9c4b01ee = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _b9a20788 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _025e5ede = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _8a839c20 = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _442d9130 = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _5a2ccab0 = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _3cd79738 = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))
const _dd41c298 = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _cdabd080 = () => interopDefault(import('../pages/catalog/Category.vue' /* webpackChunkName: "pages/catalog/Category" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _5246dba0,
    name: "checkout",
    children: [{
      path: "konto",
      component: _30a1aba4,
      name: "checkout-account"
    }, {
      path: "bestilling",
      component: _1edb950f,
      name: "checkout-order"
    }, {
      path: "takk",
      component: _bcd2f3b8,
      name: "checkout-thank-you"
    }]
  }, {
    path: "/customer/reset-password",
    component: _f119e44c,
    alias: "/customer/account/createPassword",
    name: "reset-password"
  }, {
    path: "/customer",
    component: _7deec431,
    meta: {"titleLabel":"Account"},
    name: "customer",
    children: [{
      path: "my-profile",
      component: _8474314a,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile"
    }, {
      path: "addresses-details",
      component: _a38eaec2,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "addresses-details/create",
      component: _547790ab,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _4a7f958f,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history",
      component: _0d4da442,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _05259898,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }]
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _9c4b01ee,
    name: "attributeCollection"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _b9a20788,
    name: "attributeCollectionItem"
  }, {
    path: "/faq.html",
    component: _025e5ede,
    name: "faq"
  }, {
    path: "/aktuelt/:slug+",
    component: _8a839c20,
    name: "blogPost"
  }, {
    path: "/aktuelt",
    component: _442d9130,
    name: "blog"
  }, {
    path: "/search",
    component: _5a2ccab0,
    name: "search"
  }, {
    path: "/:slug+",
    component: _3cd79738,
    name: "page"
  }, {
    path: "/",
    component: _dd41c298,
    name: "home"
  }, {
    path: "/catalog/Category",
    component: _cdabd080,
    name: "catalog-Category"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
